import { Router } from 'framework7/types';
import { FC, ReactNode } from 'react';
import { WarrantyCancel } from '../../WarrantyManagement/WarrantyCancel/WarrantyCancel';
import { WarrantyHelp } from '../../WarrantyManagement/WarrantyHelp/WarrantyHelp';
import { WarrantyAutoRenewal } from '../WarrantyAutoRenewal/WarrantyAutoRenewal';
import { DisplayWarrantyDetail } from '../WarrantyDetailHelper';
import styles from './WarrantyDetailStyle.module.css';
import { WarrantyCard } from '@/components/projects/WarrantyCard/WarrantyCard';
import Accordion from '@/components/uiParts/Accordion/Accordion';
import SubItemList from '@/components/uiParts/Accordion/SubItemList/SubItemList';
import { InfoCard } from '@/components/uiParts/InfoCard/InfoCard';
import { List } from '@/components/uiParts/List/List';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { WARRANTY_STATUS } from '@/consts/warranty';
import {
  isCancellationInProcess,
  switchWarrantyPartsTitleByCoverageType,
} from '@/utils/warranty';
import './WarrantyDetailStyles.scss';

interface WarrantyDetailProps {
  f7router: Router.Router;
  warrantyNo: string;
  displayWarrantyDetail: DisplayWarrantyDetail;
  isWarrantyUnderContract: boolean;
  isInCancellation: boolean;
  isAllowToChangePlan: boolean;
  isUpdatable: boolean;
  handleClickCancel: () => void;
  handleClickChangePlan: () => void;
}

interface ListItem {
  label: string;
  value: string | ReactNode;
}

export const WarrantyDetail: FC<WarrantyDetailProps> = ({
  f7router,
  warrantyNo,
  displayWarrantyDetail,
  isWarrantyUnderContract,
  isInCancellation,
  isAllowToChangePlan,
  isUpdatable,
  handleClickCancel,
  handleClickChangePlan,
}) => {
  const {
    warrantyName,
    warrantyStartDate,
    warrantyEndDate,
    cancellationPeriodTo,
    InHouseDeductible,
    otherCompanyDeductible,
    guaranteeLimit,
    limitDetail,
    selfPayRate,
    affiliateName,
    updateMonthAgo,
    partsList,
    isWarrantyStarted,
    warrantyStatus,
  } = displayWarrantyDetail;
  const warrantyStatusExpired = warrantyStatus === WARRANTY_STATUS.EXPIRED;

  // 割合負担の値を取得（例: 数値または NULL）
  const burdenPercentage = selfPayRate; // 割合負担の商品データ（例: 60、0、または NULL）

  // 表示内容の生成
  const displayCustomerBurdenRatio =
    burdenPercentage && burdenPercentage > 0
      ? `保証対象となりました修理費用のうち${
          burdenPercentage / 10
        }割をご負担いただきます`
      : 'ー';

  // 表示例
  console.log(displayCustomerBurdenRatio);
  // お客様ご負担割合
  console.log(burdenPercentage);

  // 保証利用可能上限額（税別）
  console.log(limitDetail);

  // 保証額（税別）
  console.log(guaranteeLimit);

  const endDateValue = (
    endDate: DisplayWarrantyDetail['warrantyEndDate'],
    updateMonthAgo: DisplayWarrantyDetail['updateMonthAgo'],
  ) => (
    <div>
      <p>{endDate}</p>
      {isWarrantyUnderContract &&
        !isInCancellation &&
        !warrantyStatusExpired &&
        isUpdatable && (
          <div className={styles['list-supplement']}>
            <p>更新の{updateMonthAgo}ヶ月前です</p>
            <p>変更がない場合は、同じプランで自動更新されます。</p>
          </div>
        )}
    </div>
  );
  // 保守情報表示用配列を作成
  const warranties = [
    {
      label: '保証名',
      value: warrantyName,
    },
    !warrantyStatusExpired ? { label: '保証番号', value: warrantyNo } : null,
    { label: '保証開始日', value: warrantyStartDate },
    {
      label: '保証終了日',
      value: endDateValue(warrantyEndDate, updateMonthAgo),
    },
    {
      label: '免責金',
      value: (
        <div>
          <p>{`販売店入庫：${Number(InHouseDeductible).toLocaleString()}円`}</p>
          <p>
            {`他社様への入庫：${Number(
              otherCompanyDeductible,
            ).toLocaleString()}円`}
            <p>※一度の修理受付につき、</p>
            <p>「修理事務手数料として」</p>
          </p>
        </div>
      ),
    },
    {
      label: 'お客様ご負担割合',
      value: displayCustomerBurdenRatio,
    },
    limitDetail && {
      label: '保証利用可能上限額（税別）',
      value: `${limitDetail}`
    },
  ];

  const warrantyList = warranties.filter((warranty) => warranty) as ListItem[];

  if (!isWarrantyStarted) {
    warrantyList.splice(1, 1);
  }

  return (
    <PageTemplate
      pageName="WarrantyDetail"
      title={warrantyName || ''}
      showNavBack
    >
      <RoundSheet>
        {warrantyStatusExpired && (
          <InfoCard
            className="warning-card"
            bgColor="warning"
            text="保証が失効しています"
            iconType="confirm-notification"
          />
        )}

        {!warrantyStatusExpired && (
          <>
            {isWarrantyStarted ? (
              <WarrantyCard
                warrantyCardInfo={{
                  warrantyName: warrantyName ?? '',
                  warrantyNo,
                  affiliateName,
                }}
              />
            ) : (
              <InfoCard
                className="warning-card"
                bgColor="warning"
                text="適用開始前の保証です"
                iconType="confirm-notification"
              />
            )}
          </>
        )}
        <List list={warrantyList} labelWidth="40%" />
        <br />
        <br />
      </RoundSheet>
      <br />

      <RoundSheet>
        <h3 className={styles.help}>
          {switchWarrantyPartsTitleByCoverageType(
            displayWarrantyDetail.coveringType,
          )}
        </h3>
        {displayWarrantyDetail.coveringType !== null && (
          <Accordion
            items={partsList}
            renderTitle={(item) => item.m_accessory_category_name}
            renderContent={(item, index) => (
              <SubItemList
                subItems={item.subItems}
                key={index}
                renderSubItem={(subItem) => subItem.parts_name || ''}
              />
            )}
          />
        )}
      </RoundSheet>
      <br />
      {/* 保証に関するヘルプ・規約 */}
      <WarrantyHelp warrantyNo={warrantyNo} f7router={f7router} />
      <br />
      {/* 自動更新型保証につきまして */}
      {isUpdatable && <WarrantyAutoRenewal />}
      <br />
      {/* 保証を解約する */}
      {!isCancellationInProcess(
        displayWarrantyDetail.applyStatus,
        displayWarrantyDetail.warrantyStatus,
      ) &&
        (isWarrantyUnderContract || !isWarrantyStarted) && (
          <WarrantyCancel
            f7router={f7router}
            handleClickLink={handleClickCancel}
            isCancelable={displayWarrantyDetail.cancelEnableTerm}
            isUpdatable={isUpdatable}
            cancelableTerm={displayWarrantyDetail.cancelEnableTermEnd}
          />
        )}
      <br />
      {/* {isAllowToChangePlan && (
        <Button onClick={handleClickChangePlan} height="48px">
          プラン変更お手続き
        </Button>
      )} */}
    </PageTemplate>
  );
};
