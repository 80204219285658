import { format } from 'date-fns';
import { f7 } from 'framework7-react';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormInput } from '../TradeReservation/tradeReservationSchema';
import styles from './RepairConfirmationStyle.module.scss';
import { SuccessPopup } from './SuccessPopup';
import { postDealRepairRequest } from '@/api/carRepairApi';
import { postDealWarrantyUpdateApi } from '@/api/warrantyApi';
import { Button } from '@/components/uiParts/Button';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import {
  CHECK_EXPECTED_ATS,
  CheckExpectedAt,
  IDENTIFICATION_SUBSCRIBERS,
  IdentificationSubscriber,
  MAP_CHECK_EXPECTED_AT_NUMBER,
  MAP_IDENTIFICATION_SUBSCRIBER_NUMBER,
} from '@/consts/carRepair';
import { customerType } from '@/consts/customer';
import { PROCESS_KB } from '@/consts/warranty';
import { DealRepairRequestParams } from '@/types/api/carRepairApi';
import { formatDateWithDay } from '@/utils/date';

type RepairConfirmationProps = {
  repairId: number;
  repairReceptId: number;
};

export const RepairConfirmation: FC<RepairConfirmationProps> = ({
  repairId,
  repairReceptId = 0,
}) => {
  const { hiragana, registry_no, car_nm } = store.state.carItemInfo;
  const {
    first_name,
    family_name,
    first_name_kana,
    family_name_kana,
    customer_type,
    company_name,
    company_name_kana,
  } = store.state.customerInfo;
  const { m_customer_id } = store.state.authInfo;
  const { getValues } = useFormContext<FormInput>();
  const [isOpenSuccessPopup, setIsOpenSuccessPopup] = useState(false);
  const {
    is_checked_expected_nyukosaki,
    is_checked_identification_subscriber,
    expected_nyuko_date1,
    expected_nyuko_time1,
    expected_nyuko_date2,
    expected_nyuko_time2,
    expected_nyuko_date3,
    expected_nyuko_time3,
    contact_person_phone_number,
    contact_person_name,
    contact_person_name_kana,
  } = getValues();

  const checkExpectedAt = useMemo(
    () =>
      CHECK_EXPECTED_ATS.find(
        (checkExpectedAt) =>
          checkExpectedAt.value == is_checked_expected_nyukosaki,
      ),
    [is_checked_expected_nyukosaki],
  );

  const getIdentificationSubscriber = () =>
    IDENTIFICATION_SUBSCRIBERS.find(
      (identificationSubscriber) =>
        identificationSubscriber.value ==
        getValues('is_checked_identification_subscriber'),
    );
  const identificationSubscriber = getIdentificationSubscriber();
  const preparePersonNameDefault = useCallback(() => {
    if (customer_type === customerType.CORPORATION) {
      return company_name || '';
    }

    if (customer_type === customerType.TRADER && company_name) {
      return company_name;
    }

    return `${family_name || ''}　${first_name || ''}`;
  }, [company_name, customer_type, family_name, first_name]);
  const preparePersonNameKanaDefault = useCallback(() => {
    if (customer_type === customerType.CORPORATION) {
      return company_name_kana || '';
    }

    if (customer_type === customerType.TRADER && company_name_kana) {
      return company_name_kana;
    }

    return `${family_name_kana || ''}　${first_name_kana || ''}`;
  }, [company_name_kana, customer_type, family_name_kana, first_name_kana]);
  const getRepairRequestParam = useMemo(
    (): DealRepairRequestParams => ({
      repair_id: repairId,
      t_uketuke_warranty: {
        is_checked_expected_nyukosaki:
          MAP_CHECK_EXPECTED_AT_NUMBER[is_checked_expected_nyukosaki],
        expected_nyuko_date1:
          is_checked_expected_nyukosaki != CheckExpectedAt.EXTERNAL_FACTORY
            ? format(expected_nyuko_date1 as Date, 'yyyy-MM-dd')
            : '',
        expected_nyuko_time1:
          is_checked_expected_nyukosaki != CheckExpectedAt.EXTERNAL_FACTORY
            ? (expected_nyuko_time1 as string)
            : '',
        expected_nyuko_date2:
          is_checked_expected_nyukosaki != CheckExpectedAt.EXTERNAL_FACTORY &&
          expected_nyuko_date2 &&
          expected_nyuko_time2
            ? format(expected_nyuko_date2 as Date, 'yyyy-MM-dd')
            : '',
        expected_nyuko_time2:
          is_checked_expected_nyukosaki != CheckExpectedAt.EXTERNAL_FACTORY &&
          expected_nyuko_date2 &&
          expected_nyuko_time2
            ? expected_nyuko_time2
            : '',
        expected_nyuko_date3:
          is_checked_expected_nyukosaki != CheckExpectedAt.EXTERNAL_FACTORY &&
          expected_nyuko_date3 &&
          expected_nyuko_time3
            ? format(expected_nyuko_date3 as Date, 'yyyy-MM-dd')
            : '',
        expected_nyuko_time3:
          is_checked_expected_nyukosaki != CheckExpectedAt.EXTERNAL_FACTORY &&
          expected_nyuko_date3 &&
          expected_nyuko_time3
            ? expected_nyuko_time3
            : '',
        is_checked_identification_subscriber:
          MAP_IDENTIFICATION_SUBSCRIBER_NUMBER[IdentificationSubscriber.MYSELF],
        contact_person_name:
          is_checked_identification_subscriber == IdentificationSubscriber.AGENT
            ? `${contact_person_name?.family_name}　${contact_person_name?.first_name}`
            : preparePersonNameDefault(),
        contact_person_name_kana:
          is_checked_identification_subscriber == IdentificationSubscriber.AGENT
            ? `${contact_person_name_kana?.family_name}　${contact_person_name_kana?.first_name}`
            : preparePersonNameKanaDefault(),
        contact_person_phone_number: `${contact_person_phone_number?.tel1}-${contact_person_phone_number?.tel2}-${contact_person_phone_number?.tel3}`,
      },
    }),
    [
      contact_person_name?.family_name,
      contact_person_name?.first_name,
      contact_person_name_kana?.family_name,
      contact_person_name_kana?.first_name,
      contact_person_phone_number?.tel1,
      contact_person_phone_number?.tel2,
      contact_person_phone_number?.tel3,
      expected_nyuko_date1,
      expected_nyuko_date2,
      expected_nyuko_date3,
      expected_nyuko_time1,
      expected_nyuko_time2,
      expected_nyuko_time3,
      is_checked_expected_nyukosaki,
      is_checked_identification_subscriber,
      preparePersonNameDefault,
      preparePersonNameKanaDefault,
      repairId,
    ],
  );

  const toSendApiCreateRepairRequest = async () => {
    f7.preloader.show();
    try {
      const response = await postDealRepairRequest(getRepairRequestParam);
      await postDealWarrantyUpdateApi({
        t_repair_recept_id: repairReceptId,
        m_customer_id,
        process_kb: PROCESS_KB.CONTINUE_USE_AFTER_REVIEW,
        issues: [],
        documents: [],
      });
      if (response?.data?.success) {
        return setIsOpenSuccessPopup(true);
      }
      f7.dialog.alert(
        response?.data?.error_message
          ? response?.data?.error_message.split(',').join(' ')
          : '修理フォーム送信時のエラー',
        '送信リクエストの失敗',
        () => {},
      );
    } finally {
      f7.preloader.hide();
    }
  };

  useEffect(() => {
    document.querySelector('.page-content > div > div')?.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles['container']}>
      <div>
        <h3 className={styles['heading']}>依頼内容</h3>
        <p className={styles['heading-content']}>依頼内容をご確認ください。</p>
      </div>
      <div className={styles['content-row']}>
        <label>対象車両</label>
        <div>
          {[hiragana, registry_no, car_nm].filter((value) => !!value).join(' ')}
        </div>
      </div>
      <div className={styles['content-row']}>
        <label>作業内容</label>
        <div>取引保証での修理</div>
      </div>
      <div className={styles['content-row']}>
        <label>車の入庫先</label>
        <div>{checkExpectedAt?.text}</div>
      </div>
      {checkExpectedAt?.value != CheckExpectedAt.EXTERNAL_FACTORY && (
        <div className={styles['content-group']}>
          <h3>ご依頼希望日時</h3>
          <div className={styles['content-row']}>
            <label>第一希望</label>
            <div>
              {`${formatDateWithDay(
                expected_nyuko_date1 as Date,
              )} ${expected_nyuko_time1}`}
            </div>
          </div>
          <div className={styles['content-row']}>
            <label>第二希望</label>
            <div>
              {expected_nyuko_date2 &&
                expected_nyuko_time2 &&
                `${formatDateWithDay(
                  expected_nyuko_date2 as Date,
                )} ${expected_nyuko_time2}`}
            </div>
          </div>
          <div className={styles['content-row']}>
            <label>第三希望</label>
            <div>
              {expected_nyuko_date3 &&
                expected_nyuko_time3 &&
                `${formatDateWithDay(
                  expected_nyuko_date3 as Date,
                )} ${expected_nyuko_time3}`}
            </div>
          </div>
        </div>
      )}

      <div className={styles['content-group']}>
        <h3>ご連絡先</h3>
        <div className={styles['content-row']}>
          <label>ご連絡先</label>
          <div>
            {contact_person_phone_number?.tel1}-
            {contact_person_phone_number?.tel2}-
            {contact_person_phone_number?.tel3}
          </div>
        </div>
        <div className={styles['content-row']}>
          <label>
            ご連絡先電話番号と
            <br /> 契約者様の関係
          </label>
          <div>{identificationSubscriber?.text}</div>
        </div>
        <div className={styles['content-row']}>
          <label>連絡方法</label>
          <div>電話</div>
        </div>
        {identificationSubscriber?.value == IdentificationSubscriber.AGENT && (
          <>
            <div className={styles['content-row']}>
              <label>お名前</label>
              <div className={styles['content-name-text']}>
                <span>{`${contact_person_name?.family_name}　${contact_person_name?.first_name}`}</span>
              </div>
            </div>
            <div className={styles['content-row']}>
              <label>フリガナ</label>
              <div className={styles['content-name-text']}>
                <span>{`${contact_person_name_kana?.family_name}　${contact_person_name_kana?.first_name}`}</span>
              </div>
            </div>
          </>
        )}
      </div>

      <div className={styles['note-group']}>
        <h3>
          <i className="icon-exclamation-mark-red" />
          ご注意事項
        </h3>
        <p className={styles['black-bullet']}>
          保証のご利用につきましては必ずお見積もり書の確認及び審査を伴います。
        </p>
        <p className={styles['black-bullet']}>
          審査結果連絡を待たずに部品手配依頼や修理着工依頼をしてしまいますといかなる内容の場合も保証適用外となります。
        </p>
        <p className={styles['black-bullet']}>
          部品交換が必要な場合は、中古部品の使用が最優先となります。中古部品の調達ができない場合はリビルト部品・社外新品・純正新品の優先順位での対応となります。
        </p>
        <p className={styles['black-bullet']}>
          代車費用につきましては、保証サービスに含まれません。 （※
          一部プランは除く。）
        </p>
        <p className={styles['black-bullet']}>
          受付いただきました工場にて保証修理対応をいただけない場合がございます。その場合、工場の移動をお願いさせていただくことがございます。
        </p>
      </div>
      <Button
        type="button"
        className={styles['submit-button']}
        text="この内容で依頼する"
        handleClickAction={toSendApiCreateRepairRequest}
      />
      <SuccessPopup
        isModalOpen={isOpenSuccessPopup}
        onPopupClose={() => {
          setIsOpenSuccessPopup(false);
          f7.views.main.router.navigate(paths.tradeGuaranteeApplication, {
            animate: false,
          });
        }}
      />
    </div>
  );
};
