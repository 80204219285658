import { formatYearMonthToJa, hyphenToDay } from './date';
import {
  PaymentsHistoryInfo,
  PaymentsHistoryInfoDetail,
} from '@/components/pages/PaymentsHistory2/PaymentsHistory2/PaymentsHistory2';
import {
  BillInfo,
  BillInfoDetail,
} from '@/components/pages/PaymentsSchedule/PaymentsSchedule/PaymentsSchedule';
import { invalidateFlag, noPayFlg, paymentTypeId } from '@/consts/payment';
import {
  BillInformation,
  BillInformationDetail,
} from '@/types/api/customerApi';
import { PaymentTypeId } from '@/types/payment';

export const getBillInfoList = (billInfo: BillInformation[]) => {
  let listno = 0;
  const billInfoList: BillInfo[] = billInfo.map((item) => {
    let initialBillInfo: BillInfo = {};
    let initialBillDetail: BillInfoDetail = {};
    let listdetailno = 0;
    if (item.bill_infomation_details) {
      const billInfoDetail = item.bill_infomation_details.map((detail) => {
        const billingDate = hyphenToDay(detail.billing_date ?? '') + '予定';
        initialBillDetail.bill_price = detail.bill_price;
        initialBillDetail.pay_subject = detail.pay_subject;
        initialBillDetail.billing_date = billingDate;
        initialBillDetail.payment_type_name = detail.payment_type_name;
        initialBillDetail.bank_name = detail.bank_name;
        initialBillDetail.card_no = detail.card_no;
        initialBillDetail.account_number = detail.account_number;
        initialBillDetail.m_payment_type_id = detail.m_payment_type_id;
        initialBillDetail.listdetailno = listdetailno++;
        return initialBillDetail;
      });
      const nextBillYm =
        hyphenToDay(item.next_bill_date ?? '').substring(0, 7) + '月請求分';
      const nextBillDate = hyphenToDay(item.next_bill_date ?? '') + '日予定';
      initialBillInfo.next_bill_ym = nextBillYm;
      initialBillInfo.next_bill_date = nextBillDate;
      initialBillInfo.next_bill_amount = item.next_bill_amount;
      initialBillInfo.billinfoDetail = billInfoDetail;
      initialBillInfo.listno = listno++;
    }

    return initialBillInfo;
  });

  return billInfoList;
};

export const getBillDetail = (billInfoDetail: BillInfoDetail) => {
  //ToDo: マジックナンバー調査
  const paymentType =
    billInfoDetail.m_payment_type_id == 4
      ? billInfoDetail.bank_name + '\n' + billInfoDetail.account_number
      : billInfoDetail.card_no;
  const billingTitle =
    billInfoDetail.m_payment_type_id == 4 ? '振込期限' : '請求予定日';

  const list = [
    {
      label: '請求対象',
      description1: billInfoDetail.pay_subject,
      description2: '',
    },
    {
      label: '請求金額',
      description1: billInfoDetail.bill_price ?? 0 + ' 円',
      description2: '',
    },
    {
      label: '決済方法',
      description1: billInfoDetail.payment_type_name,
      description2: paymentType,
      paymentType: billInfoDetail.m_payment_type_id,
    },
    {
      label: billingTitle,
      description1: hyphenToDay(billInfoDetail.billing_date ?? '', true),
      description2: '',
    },
    {
      label: '備考',
      description1: billInfoDetail.memo,
      description2: '',
    },
  ];
  return list;
};

export const getPaymentsHistory = (paymentsHistory: BillInformation[]) => {
  const paymentsHistoryInfoList: PaymentsHistoryInfo[] = paymentsHistory.map(
    (item) => {
      let initialPaymentsHistoryInfo: PaymentsHistoryInfo = {};
      let initialPaymentsHistoryDetail: PaymentsHistoryInfoDetail = {};
      if (item.bill_infomation_details) {
        const paymentsHistoyInfoDetail = item.bill_infomation_details.map(
          (detail) => {
            const billingDate = hyphenToDay(detail.billing_date ?? '') + '予定';
            initialPaymentsHistoryDetail.bill_price = detail.bill_price;
            initialPaymentsHistoryDetail.pay_subject = detail.pay_subject;
            initialPaymentsHistoryDetail.billing_date = billingDate;
            initialPaymentsHistoryDetail.payment_type_name =
              detail.payment_type_name;
            initialPaymentsHistoryDetail.bank_name = detail.bank_name;
            initialPaymentsHistoryDetail.card_no = detail.card_no;
            initialPaymentsHistoryDetail.account_number = detail.account_number;
            initialPaymentsHistoryDetail.m_payment_type_id =
              detail.m_payment_type_id;
            return initialPaymentsHistoryDetail;
          },
        );
        const nextBillYm =
          hyphenToDay(item.next_bill_date ?? '').substring(0, 7) + '月請求分';
        initialPaymentsHistoryInfo.billing_date_ym = nextBillYm;
        initialPaymentsHistoryInfo.bill_price_total = item.next_bill_amount;
        initialPaymentsHistoryInfo.billinfoDetail = paymentsHistoyInfoDetail;
      }

      return initialPaymentsHistoryInfo;
    },
  );

  return paymentsHistoryInfoList;
};

export const switchPaymentIcon = (type: PaymentTypeId) => {
  switch (type) {
    case paymentTypeId.CREDIT:
    case paymentTypeId.PAY_EASY:
      return 'icon-credit-card';
    case paymentTypeId.BANK:
      return 'icon-bank';
    default:
      return '';
  }
};

export const calcTotalBillPrice = (billDetails: BillInformationDetail[]) => {
  let totalBillPrice = 0;
  billDetails.forEach((detail) => {
    if (detail.bill_price) {
      totalBillPrice += detail.bill_price;
    }
  });
  return totalBillPrice;
};

export const isBankOrPayEasy = (paymentType: number | undefined) => {
  return (
    paymentType === paymentTypeId.BANK || paymentType === paymentTypeId.PAY_EASY
  );
};

export const switchPaymentMethodDesc = (
  paymentType: number,
  bankName: string = '',
  paymentTypeName?: string,
) => {
  switch (paymentType) {
    case paymentTypeId.CREDIT:
      return 'クレジットカード支払い';
    case paymentTypeId.BANK:
      return `${bankName}から引き落とし`;
    case paymentTypeId.PAY_EASY:
      if (paymentTypeName === undefined) {
        return 'ペイジー(店頭)';
      }
      return `${paymentTypeName ?? ''} (${bankName})`;
    default:
      return '';
  }
};

export const createPaymentText = (num: number) => {
  const oneTime = 1;
  return num === oneTime ? '一括払い' : `分割(${num}回払い)`;
};

export const createPaymentMethodText = (
  paymentTypeId: number | undefined,
  bankName: string | undefined,
  accountNumber: string | undefined,
  cardNo: string | undefined,
) => {
  return isBankOrPayEasy(paymentTypeId)
    ? `${bankName ?? ''}${accountNumber ?? ''}`
    : cardNo ?? '';
};

export const createPaymentBankNameText = (
  billDetail: BillInformationDetail,
) => {
  return billDetail.m_payment_type_id === paymentTypeId.BANK
    ? billDetail.bank_name ?? ''
    : `${billDetail.payment_type_name ?? ''}(${billDetail.bank_name ?? ''})`;
};

export const makeBillDetailsGroupByBillMonth = (
  billDetails: BillInformationDetail[],
) => {
  const newObj: { [key in string]: BillInformationDetail[] } = {};
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  billDetails.forEach((el) => {
    const billDate = new Date(el.billing_date as string);
    if (
      el.bill_month &&
      billDate >= now &&
      el.no_pay_flg === noPayFlg.UNTREATED &&
      el.invalidate_flg == invalidateFlag.NOT_EXPIRED
    ) {
      const month = el.bill_month; // 例: 202201
      if (!newObj[month]) {
        newObj[month] = [el];
      } else {
        newObj[month] = [...newObj[month], el];
      }
    }
  });

  return Object.keys(newObj)
    .filter((key) => newObj[key].length)
    .map((key) => {
      return {
        month: `${formatYearMonthToJa(key)}`,
        billDetails: newObj[key],
        totalPrice: calcTotalBillPrice(newObj[key]),
      };
    });
};
