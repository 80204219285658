import { Store } from 'framework7/types';
import { UpdateWarranty } from '@/components/pages/UpdateWarranty/UpdateWarrantyHelper';
import { setupProcessingStatus } from '@/consts/linkDrive';
import { loginType } from '@/consts/login';
import { ThemeId } from '@/consts/shop';
import { Affiliate } from '@/types/api/authApi';
import {
  BillInformation,
  Campaign,
  Car,
  Customer,
  NextWarranty,
  Notification,
  PaymentMethod,
  Prepaid,
  Warranty,
} from '@/types/api/customerApi';
import { LinkDriveOwner } from '@/types/api/infoApi';
import {
  ContractInformation,
  ErrorType,
  ScanResult,
} from '@/types/api/linkDriveApi';
import { Houseprepaid } from '@/types/api/prepaidApi';
import { InqueryDetail, InquiryHeader } from '@/types/api/queryApi';
import { Reservation } from '@/types/api/reservationApi';
import { LoginType } from '@/types/login';

export type LoginInfoState = {
  email: string;
  tel: string;
  loginType: LoginType;
};

export type AuthInfoState = {
  m_customer_id: number;
  affiliate_app_type?: number;
  token: string;
  isLogin: boolean;
};

export type PaymentDetail = {
  accountNumber: string;
  bankName: string;
  cardNo: string;
  isSelected: boolean;
  noPayFlg: number;
  paySubject: string;
  paymentMethodId: number;
  paymentTypeId: number;
  paymentTypeName: string;
  warrantyNo: string;
  register: string;
};

export type State = {
  themeId: ThemeId;
  globalTheme: number | null;
  menu: string;
  loginInfo: LoginInfoState;
  isLoggedInToMaikuru: boolean; // 加盟店ではなく、マイくるにログインしているかどうか
  authInfo: AuthInfoState;
  emailLoginInfo: {
    token: string;
  };
  customerInfo: Required<Customer>;
  carItemInfo: Required<Car>;
  carsInfo: Required<Car>[];
  billsInfo: Required<BillInformation>[];
  paymentMethodsInfo: Required<PaymentMethod>[];
  advancePaymentsInfo: Required<Prepaid>[];
  notificationsInfo: Required<Notification>[];
  campaignsInfo: Required<Campaign>[];
  isInfoLoaded: boolean;
  isImpactInfoChecked: boolean;
  activeTabId: number;
  ownerId: number;
  fromPage: string;
  linkDriveCheck: boolean;
  linkDriveProgress: number;
  linkDriveUpdating: number;
  linkDriveAbortController: AbortController;
  linkDriveSetupCurrent: string;
  linkDriveOwnerInfo: LinkDriveOwner;
  contractInfo: ContractInformation;
  recordDetailItem: {
    id: string;
    driving_duration_start: string;
    driving_duration_end: string;
    driving_time: number;
    hours: number;
    minutes: number;
    driving_distance: number;
    fuel_efficiency: number;
    phyd_score_level: string;
  };
  affiliatesInfo: Affiliate[];
  emailDuplicated: boolean;
  adTelemileageContent: {
    content: string;
    m_car_id: number;
  };

  // cars
  serialNumberInput: string;

  coupons: {
    t_campaigns_id: string;
    use_date: string;
    title: string;
    t_end_date: string;
  }[];
  serialNumberDb: string;
  linkDriveSetupStatusCompleted: boolean;

  // warranty
  updateWarrantyInfo: UpdateWarranty;
  updateWarrantyLoan: any;
  updateLoan: { loan_num: number; loan_num_f: number; loan_num_s: number };
  calibrationInterruptionStep: number;
  calibrationJobId?: string;
  reserveAmount: number;
  paymentInfo: {
    mPaymentTypeId: number;
    mPaymentMethodId: number;
    paymentTypeName: string;
    bankName: string;
    cardNo: string;
    accountNumber: string;
  };
  mPrepaidPlanId: number;
  // permission
  permission: {
    gpsPermission: boolean;
    cameraPermission: boolean;
    pushPermission: boolean;
  };
  scanResults: ScanResult[];
  filteredScanResult: ScanResult;
  scanResultErrors: ErrorType[];
  nowSpecialContract: {
    specialContract: string;
    cancelKbn: string;
    expireDate: string;
    contractExpireDate: string;
    issueDate: string;
    contractIssueDate: string;
    phydScoreKind: string;
  };
  monthlyReportDate: string;
  paymentTempData: {
    type: string;
    openDialogName: string;
    step?: number;
    isTransition: boolean;
  };
  updateWarranties: UpdateWarranty[];
  term: {
    app: string;
    privacyPolicy: string;
    score: string;
    linkDrive: string;
    roadService: string;
    prepaid: string;
  };
  prepaidPlanList: Houseprepaid[];
  prepaidPlanName: string;
  settingsPrevPage: string;
  consultingHistoryList: InquiryHeader[];
  consultingHistoryDetail: InqueryDetail[];
  chargePrice: number;
  prepaidChargePayment: PaymentDetail;
  warranties: Warranty[];
  nextWarranties: NextWarranty[];
  currentWarranty: Warranty | undefined;
  nextWarranty: NextWarranty | undefined;
  paymentListPrevPage: string;
  GMOCardParams: {
    MemberPassString: string;
    DateTime: string;
    MemberID: string;
  };
  diagnosticDate: string;
  selectedDay?: Date;
  deeplink: string;
  reservation: Reservation;
  contractSignature?: string;
};

// Getter modification
export type Getter = {
  loginInfo: () => LoginInfoState;
  authInfo: () => AuthInfoState;
  linkDriveOwnerInfo: () => LinkDriveOwner;
  customerInfo: () => Required<Customer>;
  carItemInfo: () => Required<Car>;
  carsInfo: () => Required<Car>[];
  affiliatesInfo: () => Affiliate[];
  adTelemileageContent: () => State['adTelemileageContent'];
  globalTheme: () => number | null;
  fromPage: () => string;
  ownerId: () => number;
  recordDetailItem: () => State['recordDetailItem'];
  serialNumberInput: () => string;
  linkDriveProgress: () => number;
  linkDriveUpdating: () => number;
  linkDriveAbortController: () => AbortController;
  filteredCoupon: () => State['coupons'];
  filteredScanResult: ScanResult;
  updateWarrantyInfo: () => UpdateWarranty;
  updateWarranties: () => UpdateWarranty[];
  updateWarrantyLoan: () => any;
  getPaymentMethodsInfo: () => Required<PaymentMethod>[];
  getFirstAdvancePaymentsInfo: ({ state }: Store) => Required<Prepaid>;
  getAdvancePaymentsInfo: ({ state }: Store) => Required<Prepaid>[];
  getBillInfo: ({ state }: Store) => Required<BillInformation>[];
  getPaymentListPreviousPage: ({ state }: Store) => string;
  getPaymentInfo: ({ state }: Store) => State['paymentInfo'];
  getPrepaidPlanId: ({ state }: Store) => number;
  getReserveAmount: ({ state }: Store) => number;
  getPrepaidPlanList: ({ state }: Store) => Houseprepaid[];
  getThemeId: ({ state }: Store) => ThemeId;
  getIsInfoLoaded: ({ state }: Store) => boolean;
  getSettingsPreviousPage: ({ state }: Store) => string;
  getDiagnosticDate: ({ state }: Store) => string;
  getActiveTabId: ({ state }: Store) => number;
  getCampaignsInfo: ({ state }: Store) => Required<Campaign>[];
  getSelectedDay: ({ state }: Store) => Date;
  getDeeplink: ({ state }: Store) => string;
  getReservation: ({ state }: Store) => Reservation;
  getContractSignature: ({ state }: Store) => string;
};

export const initialState: State = {
  themeId: 1,
  globalTheme: null,
  menu: 'home',
  loginInfo: {
    email: '',
    tel: '',
    loginType: loginType.NONE,
  },
  isLoggedInToMaikuru: false,
  authInfo: {
    m_customer_id: 0,
    isLogin: false,
    token: '',
  },
  emailLoginInfo: {
    token: '',
  },
  affiliatesInfo: [],
  emailDuplicated: false,
  customerInfo: {
    id: 0,
    affiliate_name: '',
    m_unique_customer_id: 0,
    m_affiliate_id: 0,
    customer_type: 0,
    first_name: '',
    family_name: '',
    first_name_kana: '',
    family_name_kana: '',
    company_name: '',
    company_name_kana: '',
    company_flg: 0,
    company_representative_phone_no: '',
    birthday: '',
    gender: 0,
    zip_code1: '',
    zip_code2: '',
    address1: '',
    address2: '',
    address3: '',
    email: '',
    phone_no1: '',
    phone_no2: '',
    mobile_flg1: false,
    mobile_flg2: false,
    mobile_type1: 0,
    mobile_type2: 0,
    unit_name: '',
    unit_zip_code1: '',
    unit_zip_code2: '',
    unit_address: '',
    unit_tel: '',
    unit_url: '',
    prepaid_service_flg: 0,
    carwash_service_flg: 0,
    connector_term: '',
    m_unit_id: 0,
    invoice_registration_number: '',
    affiliate_invoice_registration_number: '',
    customer_rank_name: '',
    affiliate_prepaid_name: '',
    affiliate_inquiry_form_type: 0,
  },
  carItemInfo: {
    m_car_id: 0,
    t_order_id: 0,
    t_stock_car_id: 0,
    unique_code: '',
    transport_bu: 0,
    transport_name: '',
    class_no: '',
    hiragana: '',
    registry_no: '',
    car_cd: 0,
    maker_nm: '',
    car_nm: '',
    grade: '',
    registration_date: '',
    vin: '',
    next_inspection_date: '',
    color_nm: '',
    haiki: 0,
    katasiki: '',
    keijo_nm: '',
    kudo_nm: '',
    missionkbn_nm: '',
    fuel_nm: '',
    teiin: 0,
    door_cd: '',
    carsize_l: 0,
    carsize_w: 0,
    carsize_h: 0,
    file_path: '',
    t_accessories: [],
    linkdrive_status: 0,
    ad_mileage_display_flg: 0,
    contract_id: 0,
    contract_number: 0,
    contract_number_sub: 0,
    contract_expire_date: '',
    warranties: [],
    next_warranty: [],
    before_contract_id: '',
    before_contract_number: '',
    before_contract_number_sub: '',
    before_contract_expire_date: '',
    delivery_complete_flg: '',
    delivery_confirmed_date: '',
    user_upload_image_file_path: '',
  },
  carsInfo: [],
  billsInfo: [],
  paymentMethodsInfo: [],
  advancePaymentsInfo: [],
  notificationsInfo: [],
  campaignsInfo: [],
  isInfoLoaded: false,
  isImpactInfoChecked: false,
  adTelemileageContent: {
    content: '',
    m_car_id: 0,
  },
  // linkdrive
  ownerId: 0,
  activeTabId: 0,
  linkDriveCheck: false,
  linkDriveProgress: 0,
  linkDriveUpdating: setupProcessingStatus.INACTIVE,
  linkDriveAbortController: new AbortController(),
  linkDriveSetupCurrent: '',
  linkDriveOwnerInfo: {
    id: 0,
    m_customer_id: 0,
    t_stock_car_id: 0,
    vin: '',
    owner_id: 0,
    auth_key: '',
    serial_no: '',
    model: '',
    firmware: '',
    init_flg: 0,
    init_status: 0,
    login_flg: 0,
    regist_flg: 0,
    regist_date: '',
    sim_flg: 0,
    start_usage_at: 0,
    end_usage_at: '',
    pin_code: '',
    msn: '',
    activate_expired_at: '',
    sleep: 0,
    sim_status: 0,
    ecu_type: '0',
    mode: 0,
    simple_mode_flg: 0,
    order_no: '',
    del_flg: 0,
    device_del_date: '',
    ecall_big: 0,
    ecall_middle: 0,
    ecall_small: 0,
    created_at: '',
    create_user: '',
    updated_at: '',
    update_user: '',
    drivelog_send_flg: 0,
    contract_flg: 0,
    contract_number: '',
    contract_number_sub: '',
    vehicle_id: '',
    device_key: '',
    service_start_date: '',
    service_end_date: '',
    service_restarting_date: '',
    exex_autoscan_flg: '',
    vehicle_type: '',
    setup_status: 0,
  },
  recordDetailItem: {
    id: '',
    driving_duration_start: '',
    driving_duration_end: '',
    driving_time: 0,
    hours: 0,
    minutes: 0,
    driving_distance: 0,
    fuel_efficiency: 0,
    phyd_score_level: '',
  },
  contractInfo: {
    owner_id: 0,
    vehicle_id: '',
    contract_id: 0,
    contract_id_sub: 0,
    contract_number: '',
    contract_number_sub: '',
    transfer_date: '',
    issue_date: '',
    expire_date: '',
    cancel_kbn: '',
    modified_reason: '',
    add_up_date: '',
    special_contract_cancel_date: '',
    special_contract_start_date: '',
    contract_issue_date: '',
    contract_expire_date: '',
    device_kind: '',
    device_key: '',
    special_contract: '',
    insurance_type_1: '',
    insurance_type_2: '',
    action_kbn: '',
    phyd_score_kind: '',
  },
  serialNumberInput: '',
  coupons: [],
  linkDriveSetupStatusCompleted: false,
  calibrationInterruptionStep: 0,
  updateWarrantyInfo: {
    m_warranty_plan_header_id: '',
    m_warranty_plan_detail_id: '',
    warranty_name: '',
    plan_info: '',
    warranty_type: 0,
    continuous_contract_term: 0,
    deductible_term: 0,
    deductible_s: 0,
    deductible_o: 0,
    amount_limit: '',
    limit_detail: '',
    self_pay_rate: 0,
    max_trip: 0,
    years_limit: 0,
    covering_type: '',
    warranty_fee_wh_continuous: 0,
    loan1_num_continuous: 0,
    loan1_num_f_continuous: 0,
    loan1_num_s_continuous: 0,
    loan2_num_continuous: 0,
    loan2_num_f_continuous: 0,
    loan2_num_s_continuous: 0,
  },
  updateLoan: {
    loan_num: 0,
    loan_num_f: 0,
    loan_num_s: 0,
  },
  reserveAmount: 0,
  paymentInfo: {
    mPaymentTypeId: 0,
    mPaymentMethodId: 0,
    paymentTypeName: '',
    bankName: '',
    cardNo: '',
    accountNumber: '',
  },
  mPrepaidPlanId: 0,
  permission: {
    gpsPermission: false,
    cameraPermission: false,
    pushPermission: false,
  },
  fromPage: '',
  serialNumberDb: '',
  updateWarrantyLoan: undefined,
  scanResults: [],
  filteredScanResult: {
    id: 0,
    owner_id: 0,
    diagnosed_at: '',
    powertrain: '',
    chassis: '',
    body: '',
    network: '',
    create_date: '',
    mach_flg: 0,
    remote_flg: 0,
  },
  scanResultErrors: [],
  nowSpecialContract: {
    specialContract: '',
    cancelKbn: '',
    expireDate: '',
    contractExpireDate: '',
    issueDate: '',
    contractIssueDate: '',
    phydScoreKind: '',
  },
  monthlyReportDate: '',
  paymentTempData: {
    type: '',
    openDialogName: '',
    step: 0,
    isTransition: false,
  },
  updateWarranties: [
    {
      m_warranty_plan_header_id: '',
      m_warranty_plan_detail_id: '',
      warranty_name: '',
      plan_info: '',
      warranty_type: 0,
      continuous_contract_term: 0,
      deductible_term: 0,
      deductible_s: 0,
      deductible_o: 0,
      amount_limit: '',
      limit_detail: '',
      self_pay_rate: 0,
      max_trip: 0,
      years_limit: 0,
      covering_type: '',
      warranty_fee_wh_continuous: 0,
      loan1_num_continuous: 0,
      loan1_num_f_continuous: 0,
      loan1_num_s_continuous: 0,
      loan2_num_continuous: 0,
      loan2_num_f_continuous: 0,
      loan2_num_s_continuous: 0,
    },
  ],
  term: {
    app: '',
    privacyPolicy: '',
    score: '',
    linkDrive: '',
    roadService: '',
    prepaid: '',
  },
  prepaidPlanList: [],
  prepaidPlanName: '',
  settingsPrevPage: '',
  consultingHistoryList: [],
  consultingHistoryDetail: [],
  chargePrice: 0,
  prepaidChargePayment: {
    accountNumber: '',
    bankName: '',
    cardNo: '',
    isSelected: false,
    noPayFlg: 0,
    paySubject: '',
    paymentMethodId: 0,
    paymentTypeId: 0,
    paymentTypeName: '',
    warrantyNo: '',
    register: '',
  },
  warranties: [],
  nextWarranties: [],
  currentWarranty: undefined,
  nextWarranty: undefined,
  paymentListPrevPage: '',
  GMOCardParams: {
    MemberPassString: '',
    DateTime: '',
    MemberID: '',
  },
  diagnosticDate: '',
  deeplink: '',
  reservation: {
    service_type: '',
    reservation_id: 0,
    order_date: '',
    status: 0,
    status_value: '',
    t_stock_car_id: 0,
  },
};
