import { f7 } from 'framework7-react';
import { Router } from 'framework7/types';
import { FC } from 'react';
import { CreditCardPayInfo } from '@/components/pages/PaymentsSchedule/CreditCardPayInfo/CreditCardPayInfo';
import { OfficeFeeInfo } from '@/components/pages/PaymentsSchedule/OfficeFeeInfo/OfficeFeeInfo';
import { RoundSheet } from '@/components/uiParts/Sheet/RoundSheet/RoundSheet';
import { PageTemplate } from '@/components/uiParts/Template/PageTemplate/PageTemplate';
import { paths } from '@/config/paths';
import { paymentTypeId } from '@/consts/payment';
import { BillInformationDetail } from '@/types/api/customerApi';
import { hyphenToDay } from '@/utils/date';
import { createPaymentBankNameText, isBankOrPayEasy } from '@/utils/payment';
import './paymentsScheduleStyle.scss';

export type BillInfo = {
  next_bill_ym?: string;
  next_bill_amount?: number;
  next_bill_date?: string;
  listno?: number;
  memo?: string;
  billinfoDetail?: BillInfoDetail[];
};

export type BillInfoDetail = {
  bill_price?: number;
  pay_subject?: string;
  billing_date?: string;
  payment_type_name?: string;
  bank_name?: string;
  card_no?: string;
  account_number?: string;
  m_payment_type_id?: number;
  listdetailno?: number;
  memo?: string;
};

type PaymentScheduleProps = {
  f7router: Router.Router;
  billDetailsGroupByMonth: BillDetailsGroupByMonth;
};

export type BillDetailsGroupByMonth = {
  month: string;
  billDetails: BillInformationDetail[];
  totalPrice: number;
}[];

export const PaymentsSchedule: FC<PaymentScheduleProps> = ({
  f7router,
  billDetailsGroupByMonth,
}) => {
  const handleClick = (billDetail: BillInformationDetail) => {
    f7.view.main.router.navigate(paths.billingScheduleDetail, {
      props: {
        billDetail,
      },
    });
  };
  const isBillDetails = billDetailsGroupByMonth.length !== 0;

  return (
    <PageTemplate
      pageName="PaymentsSchedule"
      title="お支払い予定"
      showNavBack={true}
    >
      <CreditCardPayInfo />
      <div className="margin-bottom" />
      <OfficeFeeInfo />
      <div className="margin-bottom" />

      {isBillDetails ? (
        <div>
          {billDetailsGroupByMonth.map((billDetailsGroup, index) => (
            <div className="card" key={index}>
              <div className="card-content">
                <div className="list">
                  <ul>
                    <li className="accordion-item">
                      <a className="item-content item-link" href="#">
                        <div className="item-inner">
                          <div className="item-title item-title01">
                            {billDetailsGroup.month}
                          </div>
                          <div className="item-title item-title02 u-font-bold">
                            {billDetailsGroup.totalPrice?.toLocaleString() ??
                              '-'}
                            円
                          </div>
                        </div>
                      </a>
                      <div className="accordion-item-content">
                        {billDetailsGroup.billDetails &&
                          billDetailsGroup.billDetails.map((billDetail) => (
                            <div
                              className="list-items"
                              key={billDetail.bill_infomation_id}
                              onClick={() => handleClick(billDetail)}
                            >
                              <div className="list-item">
                                <div className="list-item-left">
                                  <span className="item-left">
                                    {billDetail.pay_subject}
                                  </span>
                                  <span>
                                    {billDetail.memo ? billDetail.memo : ''}
                                  </span>
                                  <span className="sub-item">
                                    {`${hyphenToDay(
                                      billDetail.billing_date ?? '',
                                      true,
                                    )} ${
                                      isBankOrPayEasy(
                                        billDetail.m_payment_type_id,
                                      )
                                        ? '振込期限'
                                        : '請求予定'
                                    }`}
                                  </span>
                                </div>
                                <div className="list-item-right">
                                  <div>
                                    <span className="item-right">
                                      {billDetail.bill_price?.toLocaleString() ??
                                        '-'}
                                      円
                                    </span>
                                  </div>
                                  {billDetail.m_payment_type_id ==
                                    paymentTypeId.CREDIT && (
                                    <div className="sub-item">
                                      <i className="icon-credit-card" />
                                      <div>{billDetail.card_no}</div>
                                    </div>
                                  )}
                                  {isBankOrPayEasy(
                                    billDetail.m_payment_type_id,
                                  ) && (
                                    <div className="sub-item">
                                      <i className="icon-bank" />
                                      <span>
                                        {createPaymentBankNameText(billDetail)}
                                      </span>
                                      <div>普通{billDetail.account_number}</div>
                                    </div>
                                  )}
                                </div>
                                <div className="arrow">
                                  <i className="icon icon-right" />
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <RoundSheet>
          <div className="no-item">請求予定がありません</div>
        </RoundSheet>
      )}
    </PageTemplate>
  );
};
