import { addDays, parse } from 'date-fns';
import { BillInfoDetail } from '../PaymentsSchedule/PaymentsSchedule/PaymentsSchedule';
import { BillInformation, Car, Customer } from '@/types/api/customerApi';
import { ContinuousWarranty } from '@/types/api/warrantyApi';
import { formatToJaDate } from '@/utils/date';
import { storeDispatch } from '@/utils/store';

export type UpdateWarranty = {
  m_warranty_plan_header_id: string;
  m_warranty_plan_detail_id: string;
  warranty_name: string;
  plan_info: string;
  warranty_type: number;
  continuous_contract_term: number;
  deductible_term: number;
  deductible_s: number;
  deductible_o: number;
  amount_limit: string;
  limit_detail: number | string;
  self_pay_rate: number;
  max_trip: number;
  years_limit: number;
  covering_type: string;
  warranty_fee_wh_continuous: number;
  loan1_num_continuous: number;
  loan1_num_f_continuous: number;
  loan1_num_s_continuous: number;
  loan2_num_continuous: number;
  loan2_num_f_continuous: number;
  loan2_num_s_continuous: number;
};

export type Loan = {
  loan_num: number;
  loan_num_f: number;
  loan_num_s: number;
};

export const getWarranties = (data: ContinuousWarranty[] | undefined) => {
  const warranties = data?.map((el) => {
    const warranty: UpdateWarranty = {
      m_warranty_plan_header_id: el.m_warranty_plan_header_id,
      m_warranty_plan_detail_id: el.m_warranty_plan_detail_id,
      warranty_name: el.warranty_name,
      plan_info: el.plan_info,
      warranty_type: el.warranty_type,
      continuous_contract_term: el.continuous_contract_term,
      deductible_term: el.deductible_term,
      deductible_s: el.deductible_s,
      deductible_o: el.deductible_o,
      amount_limit: el.amount_limit,
      max_trip: el.max_trip,
      years_limit: el.years_limit,
      covering_type: el.covering_type,
      warranty_fee_wh_continuous: el.warranty_fee_wh_continuous,
      loan1_num_continuous: el.loan1_num_continuous,
      loan1_num_f_continuous: el.loan1_num_f_continuous,
      loan1_num_s_continuous: el.loan1_num_s_continuous,
      loan2_num_continuous: el.loan2_num_continuous,
      loan2_num_f_continuous: el.loan2_num_f_continuous,
      loan2_num_s_continuous: el.loan2_num_s_continuous,
      limit_detail: '',
      self_pay_rate: 0,
    };
    return warranty;
  });
  storeDispatch('setUpdateWarranties', 'updateWarranties', warranties);
  return warranties;
};

export const getCustomer = (customerInfo: Customer, carInfo: Car) => {
  const customer = {
    contractorName: customerInfo.family_name + ' ' + customerInfo.first_name,
    contractorNameKana:
      customerInfo.family_name_kana + ' ' + customerInfo.first_name_kana,
    corporationName: customerInfo.company_name,
    birthDay: customerInfo.birthday,
    mobilePhone: customerInfo.mobile_flg1
      ? customerInfo.phone_no1
      : customerInfo.phone_no2,
    homePhone: customerInfo.mobile_flg1
      ? customerInfo.phone_no2
      : customerInfo.phone_no1,
    zipCode: customerInfo.zip_code1 + '-' + customerInfo.zip_code2,
    address1: customerInfo.address1,
    address2: customerInfo.address2 ?? '' + customerInfo.address3,
    transportBu: carInfo.transport_bu,
    transportName: carInfo.transport_name,
    classNo: carInfo.class_no,
    hiragana: carInfo.hiragana,
    registryNo: carInfo.registry_no,
    connectorTerm: customerInfo.connector_term,
  };
  return customer;
};

export const getPayment = (
  billInfomation: BillInformation[],
  warrantyNo: string,
) => {
  let payment = null;
  if (billInfomation.length > 0) {
    const billInfomationItem = billInfomation.find(
      (x: BillInformation) => x.warranty_no == warrantyNo,
    );
    const billInfomationDetail =
      billInfomationItem?.bill_infomation_details?.slice(-1)[0];
    payment = {
      warranty_no: warrantyNo,
      payment_type_id: billInfomationDetail?.m_payment_type_id ?? 0,
      payment_type_name: billInfomationDetail?.payment_type_name ?? '',
      account_number: billInfomationDetail?.account_number ?? '',
      card_no: billInfomationDetail?.card_no ?? '',
      bank_name: billInfomationDetail?.bank_name ?? '',
    };
  }
  return payment;
};

export const getCustomerStep6 = (
  customerInfo: Customer,
  carInfo: Car,
  warranty: UpdateWarranty,
  loan: Loan,
  billInfoDetail?: BillInfoDetail,
) => {
  const customer = {
    customer: {
      contractorName: customerInfo.family_name + ' ' + customerInfo.first_name,
      contractorNameKana:
        customerInfo.family_name_kana + ' ' + customerInfo.first_name_kana,
      corporationName: customerInfo.company_name,
      birthDay: customerInfo.birthday,
      mobilePhone: customerInfo.mobile_flg1
        ? customerInfo.phone_no1
        : customerInfo.phone_no2,
      homePhone: customerInfo.mobile_flg1
        ? customerInfo.phone_no2
        : customerInfo.phone_no1,
      zipCode: customerInfo.zip_code1 + '-' + customerInfo.zip_code2,
      address1: customerInfo.address1,
      address2: customerInfo.address2 ?? '' + customerInfo.address3,
    },
    car: {
      transportBu: carInfo.transport_bu,
      transportName: carInfo.transport_name,
      classNo: carInfo.class_no,
      hiragana: carInfo.hiragana,
      registryNo: carInfo.registry_no,
    },
    warranty: {
      warranty_name: warranty.warranty_name,
      deductible_term: warranty.deductible_term,
      continuous_contract_term: warranty.continuous_contract_term,
      amount_limit: warranty.amount_limit,
      warranty_fee_wh_continuous: warranty.warranty_fee_wh_continuous,
      plan_info: warranty.plan_info,
    },
    loan: {
      loan_num: loan.loan_num,
      loan_num_f: loan.loan_num_f,
      loan_num_s: loan.loan_num_s,
    },
    bill: {
      payment_type_id: billInfoDetail?.m_payment_type_id,
      payment_type_name: billInfoDetail?.payment_type_name,
      account_number: billInfoDetail?.account_number,
      card_no: billInfoDetail?.card_no,
      bank_name: billInfoDetail?.bank_name,
    },
  };
  return customer;
};

export const calcWarrantyEndDate = (date: string) => {
  const addDay = 1;

  return date !== ''
    ? formatToJaDate(addDays(parse(date, 'yyyy-MM-dd', new Date()), addDay))
    : '';
};
