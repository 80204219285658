import { differenceInDays, endOfMonth, subMonths } from 'date-fns';
import {
  calcUpdateWarrantyMonthAgo,
  isCancelEnableTerm,
  makeCancelableWarrantyObj,
} from '../WarrantyManagement/WarrantyManagementHelper';
import { PLAN_CHANGE_MONTH_BEFORE_EXPIRE } from '@/consts/warranty';
import { WarrantiesAppendWarranty } from '@/types/api/customerApi';
import { ApplyStatus } from '@/types/warranty';
import { hyphenToDayNoPadding } from '@/utils/date';
import { WarrantyPartsType, getWarrantyPartsData } from '@/utils/warranty';

export type DisplayWarrantyDetail = {
  warrantyName: string | undefined;
  warrantyStartDate: string | undefined;
  warrantyEndDate: string | undefined;
  cancellationPeriodTo: string | undefined;
  coveringType: number | undefined | null;
  InHouseDeductible: string;
  otherCompanyDeductible: string;
  guaranteeLimit: string;
  limitDetail?: string;
  selfPayRate?: number;
  affiliateName: string;
  updateMonthAgo: number;
  isWarrantyStarted: boolean;
  applyStatus: ApplyStatus | undefined;
  cancelEnableTerm: boolean;
  cancelEnableTermEnd: string;
  partsList: WarrantyPartsType;
  warrantyStatus: number | undefined;
};

export const makeDisplayWarrantyDetail = (
  affiliateName: string,
  warranty?: WarrantiesAppendWarranty,
) => {
  const cancelableWarrantyObj = makeCancelableWarrantyObj(
    warranty?.warranty_end_date,
  );

  const detail: DisplayWarrantyDetail = {
    warrantyName: warranty?.warranty_name,
    warrantyStartDate: hyphenToDayNoPadding(
      warranty?.warranty_start_date || '',
      true,
    ),
    warrantyEndDate: hyphenToDayNoPadding(
      warranty?.warranty_end_date || '',
      true,
    ),
    cancellationPeriodTo: '',
    coveringType: warranty?.covering_type,
    InHouseDeductible: warranty?.deductible_s?.toString() || '0',
    otherCompanyDeductible: warranty?.deductible_o?.toString() || '0',
    guaranteeLimit: warranty?.amount_limit
      ? `${warranty.amount_limit.toLocaleString()}円`
      : '無制限',
    limitDetail: warranty?.limit_detail
      ? `${warranty.limit_detail.toLocaleString()}`
      : '無制限',
    applyStatus: warranty?.apply_status,
    updateMonthAgo: calcUpdateWarrantyMonthAgo(
      warranty?.warranty_end_date || '',
    ),
    isWarrantyStarted:
      differenceInDays(
        new Date(warranty?.warranty_start_date || ''),
        new Date(),
      ) <= 0,
    affiliateName,
    cancelEnableTerm: isCancelEnableTerm(
      cancelableWarrantyObj?.cancelEnableTermStart ?? '',
      cancelableWarrantyObj?.cancelEnableTermEnd ?? '',
    ),
    cancelEnableTermEnd: cancelableWarrantyObj?.cancelEnableTermEnd,
    partsList: getWarrantyPartsData(warranty?.m_warranty_parts || []),
    warrantyStatus: warranty?.warranty_status,
  };
  return detail;
};

export const checkIfAllowToChangePlan = (
  isWarrantiesDuplicated: boolean,
  isUnderChanging: boolean,
  warrantyEndDate?: string,
) => {
  if (isWarrantiesDuplicated) return false;
  if (isUnderChanging) {
    return false;
  }
  const lastDayOfTwoMonthAgo = endOfMonth(
    subMonths(
      new Date(warrantyEndDate || Date.now()),
      PLAN_CHANGE_MONTH_BEFORE_EXPIRE,
    ),
  );
  const daysTillEnd = differenceInDays(lastDayOfTwoMonthAgo, new Date());
  return daysTillEnd >= 0;
};
