import { FC } from 'react';
import style from './warrantyDetailStyle.module.css';
import { WarrantiesAppendWarranty } from '@/types/api/customerApi';
import { hyphenToDay } from '@/utils/date';

type WarrantyDetailProps = {
  warrantyInfo?: WarrantiesAppendWarranty;
  warrantyNo: string;
};

export const WarrantyDetail: FC<WarrantyDetailProps> = ({
  warrantyInfo = {},
  warrantyNo = '',
}) => {
  return (
    <div className={style['list-wrapper']}>
      <div className="list-item">
        <div className="u-font-bold">保証名</div>
        <div>{warrantyInfo.warranty_name}</div>
      </div>
      <div className="list-item">
        <div className="u-font-bold">保証番号</div>
        <div>{warrantyNo}</div>
      </div>
      <div className="list-item">
        <div className="u-font-bold">保証開始日</div>
        <div>{hyphenToDay(warrantyInfo.warranty_start_date ?? '', true)}</div>
      </div>
      <div className="list-item">
        <div className="u-font-bold">保証終了日</div>
        <div>{hyphenToDay(warrantyInfo.warranty_end_date ?? '', true)}</div>
      </div>
      <div className="list-item">
        <div className="u-font-bold">免責金</div>
        <div>
          <p>
            販売店入庫：
            {warrantyInfo.deductible_s
              ? warrantyInfo.deductible_s.toLocaleString()
              : 0}
            円
          </p>
          <p>
            他社様への入庫：
            {warrantyInfo.deductible_o
              ? warrantyInfo.deductible_o.toLocaleString()
              : 0}
            円
          </p>
          <p className={style['subtext']}>
            ※一度の修理受付につき、「修理事務手数料」として
          </p>
        </div>
      </div>
      <div className="list-item">
        <div className="u-font-bold">保証限度額</div>
        <div>
          {warrantyInfo.amount_limit || warrantyInfo.limit_detail
            ? Math.max(
                warrantyInfo.amount_limit || 0
              ).toLocaleString() + ' 円'
            : '無制限'}
        </div>
      </div>
      <div className="list-item">
        <div className="u-font-bold">規定走行距離</div>
        <div>
          {warrantyInfo.max_trip ? warrantyInfo.max_trip.toLocaleString() : ''}{' '}
          km
        </div>
      </div>
      <div className="list-item">
        <div className="u-font-bold">規定経過年数</div>
        <div>{warrantyInfo.years_limit} 年未満</div>
      </div>
    </div>
  );
};
