import { f7 } from 'framework7-react';
import { FC } from 'react';
import style from './LinkDriveSetupStep3Style.module.css';
import { Button } from '@/components/uiParts/Button/Button/Button';
import { isIPhoneApp } from '@/config/device';
import { paths } from '@/config/paths';
import { store } from '@/config/store';
import { f7CustomBack } from '@/utils/utils';

interface LinkDriveSetupStep3PageProps {
  isRecallLinkDriveOwnerSuccess: boolean;
}

export const LinkDriveSetupStep3Page: FC<LinkDriveSetupStep3PageProps> = ({
  isRecallLinkDriveOwnerSuccess,
}) => {
  const ecuType = store.state.linkDriveOwnerInfo.ecu_type;

  const handleClickCalibre = () => {
    // fix on iphone app
    f7CustomBack(['#LinkDriveSetupSteps', '#LinkDriveSetupList']);
    f7.views.main.router.navigate(paths.linkDriveCalibration, {
      animate: true,
    });
  };

  const handleClose = () => {
    f7CustomBack(['#LinkDriveSetupSteps', '#LinkDriveSetupList']);
    if (!isIPhoneApp) {
      f7CustomBack();
    }
  };

  return (
    <div className={`${style['container']}`}>
      <h2 className={style['head']}>
        <i className={`icon-check ${style['head-icon']}`} />
        セットアップが完了しました
      </h2>
      {isRecallLinkDriveOwnerSuccess ? (
        <>
          <div className={style['content-box']}>
            <div className={style['content-box-head']}>
              <i className="icon-notification-settings" />
              <p>
                {Number(ecuType) == 1 || Number(ecuType) == 2
                  ? 'この車両では、スタンダードモードを使ったLINKDriveの利用が可能です'
                  : 'この車両では、ノーマルモードを使ったLINKDriveの利用が可能です'}
              </p>
            </div>
            <p>
              {Number(ecuType) == 1 || Number(ecuType) == 2
                ? 'スタンダードモードでは、メーカーの診断機と同等の幅広いデータの取得、及びGPSを使ったデータの分析が可能です。'
                : 'ノーマルモードではGPSを使ったデータの取得・分析が可能です。'}
            </p>
          </div>
        </>
      ) : (
        <>
        </>
      )}
      <div className={style['setup-note']}>
        <div className={style['setup-note-content']}>
          <p>運転スコアの計測設定までには1-2日かかります。​</p>
          <p>翌日以降にご確認をお願いいたします。</p>
        </div>
      </div>
      <div className={style['button-wrap']}>
        <Button
          isFixed
          text="閉じる"
          style={{ marginTop: '19px', background: '#EFEFEF', color: '#323232' }}
          onClick={handleClose}
        />
      </div>
    </div>
  );
};
